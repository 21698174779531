//@import url('../../../../../../../../../mdhelth/vendor_update/uti/MdHealthTheme/public/frontend/office/fonts/fontawesome/fontawesome.css');

//@font-face {
//  font-family: 'Open Sans';
//  font-style: normal;
//  font-weight: 300;
//  src: url('../fonts/open-sans-v15-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
//  src: local('Open Sans Light'), local('OpenSans-Light'),
//  url('../fonts/open-sans-v15-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//  url('../fonts/open-sans-v15-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
//  url('../fonts/open-sans-v15-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
//  url('../fonts/open-sans-v15-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
//  url('../fonts/open-sans-v15-latin_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
//}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../../fonts/open-sans-v15-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../../../fonts/open-sans-v15-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../fonts/open-sans-v15-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../fonts/open-sans-v15-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
  url('../../../fonts/open-sans-v15-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../fonts/open-sans-v15-latin_cyrillic-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../../fonts/open-sans-v15-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../../../fonts/open-sans-v15-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../fonts/open-sans-v15-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../fonts/open-sans-v15-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('../../../fonts/open-sans-v15-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../fonts/open-sans-v15-latin_cyrillic-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../../../fonts/open-sans-v15-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../../../fonts/open-sans-v15-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../fonts/open-sans-v15-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../fonts/open-sans-v15-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
  url('../../../fonts/open-sans-v15-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../fonts/open-sans-v15-latin_cyrillic-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v15-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../../../fonts/open-sans-v15-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../../fonts/open-sans-v15-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../../fonts/open-sans-v15-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
  url('../../../fonts/open-sans-v15-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../../fonts/open-sans-v15-latin_cyrillic-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'ITC Bauhaus';
  src: local('ITC Bauhaus Medium'), local('ITC-Bauhaus-Medium'),
  url('../fonts/BauhausStd-Medium.woff2') format('woff2'),
  url('../fonts/BauhausStd-Medium.woff') format('woff'),
  url('../fonts/BauhausStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}


//
//@font-face {
//  font-family: 'Font Awesome 5 Pro';
//  font-style: normal;
//  font-weight: 300;
//  font-display: auto;
//  src: url('../../../../../../../../../mdhelth/vendor_update/uti/MdHealthTheme/public/frontend/office/fonts/fontawesome/fa-light-300.woff') format('woff');
//}
//.fal {
//  font-family: 'Font Awesome 5 Pro';
//  font-weight: 300;
//}
//
//@font-face {
//  font-family: 'Font Awesome 5 Pro';
//  font-style: normal;
//  font-weight: 400;
//  font-display: auto;
//  src: url('../../../../../../../../../mdhelth/vendor_update/uti/MdHealthTheme/public/frontend/office/fonts/fontawesome/fa-regular-400.woff') format('woff');
//}
//.far {
//  font-family: 'Font Awesome 5 Pro';
//  font-weight: 400;
//}
//
//@font-face {
//  font-family: 'Font Awesome 5 Pro';
//  font-style: normal;
//  font-weight: 900;
//  font-display: auto;
//  src: url('../../../../../../../../../mdhelth/vendor_update/uti/MdHealthTheme/public/frontend/office/fonts/fontawesome/fa-solid-900.woff') format('woff');
//}
//.fa,
//.fas {
//  font-family: 'Font Awesome 5 Pro';
//  font-weight: 900;
//}
//
//
//@font-face {
//  font-family: 'Font Awesome 5 Brands';
//  font-style: normal;
//  font-weight: normal;
//  font-display: auto;
//  src: url('../../../../../../../../../mdhelth/vendor_update/uti/MdHealthTheme/public/frontend/office/fonts/fontawesome/fa-brands-400.woff') format('woff');
//}
//.fab {
//  font-family: 'Font Awesome 5 Brands';
//}


