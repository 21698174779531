html, body {
  font-size: 16px;
  overflow-x: hidden;
  @media(max-width: 1920px){
    font-size: 0.833333vw;
  }
  @media(max-width: 1199px){
    font-size: 1.21vw;
  }
  @media(max-width: 767px){
    font-size: 1.5vw;
  }
  @media(max-width: 575px){
    font-size: 3.31vw;
  }
}
body {
  font-size: $font-size-base;
  color: $font-color;
  font-family: $basefont;
  line-height: 140%;
  a{
    text-decoration: none !important;
  }
}

.errorMessage{
  color: #ff0000;
  user-select: none;
}

.container{
  width: 100%;
  max-width: 114.5rem;
  margin: 0 auto;
  padding: 0 1rem;
  @media(max-width:1199px){
    max-width: 80rem;
    padding: 0 1.5rem;
  }
}


.w-100{width: 100% !important;}
.pt-0{padding-top: 0 !important;}
.pb-0{padding-bottom: 0 !important;}
.pl-0{padding-left: 0 !important;}
.pr-0{padding-right: 0 !important;}
.mt-0{margin-top: 0 !important;}
.mb-0{margin-bottom: 0 !important;}
.mr-0{margin-right: 0 !important;}
.ml-0{margin-left: 0 !important;}
.pt-1{padding-top: 0.25rem !important;}
.mt-1{margin-top: 0.25rem !important;}
.pt-2{padding-top: 0.5rem !important;}
.mt-2{margin-top: 0.5rem !important;}
.pt-3{padding-top: 1rem !important;}
.mt-3{margin-top: 1rem !important;}
.pt-4{padding-top: 2rem !important;}
.mt-4{margin-top: 2rem !important;}
.pt-5{padding-top: 3rem !important;}
.mt-5{margin-top: 3rem !important;}
.pb-1{padding-bottom: 0.25rem !important;}
.mb-1{margin-bottom: 0.25rem !important;}
.pb-2{padding-bottom: 0.5rem !important;}
.mb-2{margin-bottom: 0.5rem !important;}
.pb-3{padding-bottom: 1rem !important;}
.mb-3{margin-bottom: 1rem !important;}
.pb-4{padding-bottom: 2rem !important;}
.mb-4{margin-bottom: 2rem !important;}
.pb-5{padding-bottom: 3rem !important;}
.mb-5{margin-bottom: 3rem !important;}
.pl-1{padding-left: 0.25rem !important;}
.ml-1{margin-left: 0.25rem !important;}
.pl-2{padding-left: 0.5rem !important;}
.ml-2{margin-left: 0.5rem !important;}
.pl-3{padding-left: 1rem !important;}
.ml-3{margin-left: 1rem !important;}
.pl-4{padding-left: 2rem !important;}
.ml-4{margin-left: 2rem !important;}
.pl-5{padding-left: 3rem !important;}
.ml-5{margin-left: 3rem !important;}
.pr-1{padding-right: 0.25rem !important;}
.mr-1{margin-right: 0.25rem !important;}
.pr-2{padding-right: 0.5rem !important;}
.mr-2{margin-right: 0.5rem !important;}
.pr-3{padding-right: 1rem !important;}
.mr-3{margin-right: 1rem !important;}
.pr-4{padding-right: 2rem !important;}
.mr-4{margin-right: 2rem !important;}
.pr-5{padding-right: 3rem !important;}
.mr-5{margin-right: 3rem !important;}
.m-auto{margin: auto !important;}
.p-0{padding: 0 !important;}
.m-0{margin: 0 !important;}
.m-auto{
  margin-left: auto;
  margin-right: auto;
}
.mb-10r{
  margin-bottom: 10rem;
  @media(max-width:991px){
    margin-bottom: 7rem;
  }
  @media(max-width:680px){
    margin-bottom: 5rem;
  }
}

//@media(min-width:767px){
//  .d-sm-none{
//    display: none !important;
//  }
//  .d-sm-block{
//    display: block !important;
//  }
//}
.font-100{font-weight: $font-100}
.font-300{font-weight: $font-300}
.font-400{font-weight: $font-400}
.font-500{font-weight: $font-500}
.font-600{font-weight: $font-600}
.font-700{font-weight: $font-700}
.font-900{font-weight: $font-900}

.font-family-base{font-family: $basefont !important;}

.text-big{font-size: $font-size-big !important}//32px
.text-xl{font-size: $font-size-xl !important}//24px
.text-lg{font-size: $font-size-lg !important}//20px
.text-md{font-size: $font-size-md !important}//18px
.text-sm{font-size: $font-size-sm !important}//16px
.text-xs{font-size: $font-size-xs !important}//14px

.gray_bg{
  background-color: $gray-white !important;
}
.white_bg{
  background-color: $white !important;
}


h1.h1{//74px
  font-size: $h1-font-size !important;
  font-family: $fontTitle;
  font-weight: 500;
  line-height: 115%; /* 85.1px */
  letter-spacing: 0.046rem;
  text-transform: uppercase;
}
h2.h2{//64px
  font-size: $h2-font-size !important;
  font-family: $fontTitle;
  font-weight: 500;
  line-height: 115%; /* 73.6px */
  letter-spacing: 0.04rem;
  padding-bottom: 2rem;
  span{
    font-family: $basefont !important;
  }
  @media(max-width:575px){
    font-size: 3.5rem !important;
  }
}
h3.h3{//58px
  font-size: $h3-font-size !important;
  font-weight: 600;
  line-height: 115%; /* 66.7px */
  letter-spacing: 0.03625rem;
  span{
    font-family: $basefont !important;
  }
}
h4.h4{//48px
  font-size: $h4-font-size;
  font-family: $fontTitle;
  font-weight: 500;
  line-height: 115%; /* 55.2px */
  letter-spacing: 0.03rem;
  margin-bottom: 2rem;
  span{
    font-family: $basefont !important;
  }
}
.h5{//40px
  font-size: $h5-font-size;
}
.h6{//32px
  font-size: $h6-font-size;
  font-weight: 700;
  line-height: 115%; /* 36.8px */
  letter-spacing: 0.08rem;
}

.color-primary{color: $dark}
.color-mint{color:  $palemint}
.color-white{color: $white}
.color-black{color: $black}
.color-gray{color: $gray}
.color-graylight{color: $font-color-light}
.color-gray100{color: $gray-100}
.color-indigo{color: $indigo}

.border-left{
  border-left: 1px solid $gray-100;
}

.text-uppercase{text-transform: uppercase;}
.text-underline{text-decoration: underline;}

.position-absolute{position: absolute;}
.position-relative{position: relative;}



@media(max-width:767px){
  .horizontal_scroll::-webkit-scrollbar {
    height: 1px;
    background-color: #eff1f7;
  }
  .horizontal_scroll::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #d9e1f0;
  }
  .horizontal_scroll::-webkit-scrollbar-track {
    background-color: #eff1f7;
  }
}


.row-cols-2{
  display: flex;
  &>*{
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.row-cols-xl-2{
  @media(min-width:1200px){
    display: flex;
    &>*{
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
.row-cols-lg-2{
  @media(min-width:992px){
    display: flex;
    &>*{
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
.row-cols-lg-2-reverse{
  @media(min-width:992px){
    display: flex;
    flex-direction: row-reverse;
    &>*{
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
.row-cols-xl-2-reverse{
  @media(min-width:1200px){
    display: flex;
    flex-direction: row-reverse;
    &>*{
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.row-cols-3{
  display: flex;
  justify-content: space-between;
  &>*{
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
}


.transparent-gray-block{
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.10);
  padding: 1.5rem;
  color: $gray-100;
  font-size: $font-size-md;
  font-weight: 400;
  line-height: 140%;
  .title{
    color: #FFF;
    font-size: $font-size-xl;
  }
  &+.transparent-gray-block{
    margin-top: 1.5rem;
  }
}
.white-bg-block{
  padding: 1.125rem 1.5rem;
  border-radius: 1rem;
  background-color: $white;
  color: $dark;
  font-size: $font-size-md;
  line-height: 140%; /* 1.575rem */
  .title{
    color: rgba(29, 29, 29, 0.65);
    font-size: $font-size-sm;
    line-height: normal;
    padding-bottom: 0.75rem;
  }
  &+.white-bg-block{
    margin-top: 1rem;
  }
  .list{
    display: flex;
    margin: 0 -0.75rem;
    .title{
      font-size: $font-size-md;
      padding: 0 0.75rem;
      flex: 0 0 12.875rem;
      max-width: 12.875rem;
      color: rgba(29, 29, 29, 0.65);
    }
    .text{
      font-size: $font-size-md;
      padding: 0 0.75rem;
      flex-grow: 1;
      color: $black;
    }
    &+.list{
      padding-top: 1.5rem;
    }
  }
}
.gray-bg-block{
  padding: 1.5rem;
  border-radius: 0.75rem;
  background: $gray-white;
  &+.gray-bg-block{
    margin-top: 1rem;
  }
}

.sub_title{
  color: $gray;
  font-size: $font-size-md;
  font-weight: 400;
  line-height: 140%;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  &-big{
    color: $dark;
    font-size: $font-size-xl;
    letter-spacing: 0.015rem;
    font-weight: 400;
    line-height: 140%;
  }
}
p{
  font-size: $font-size-md;
  line-height: 140%;
  &+p{
    margin-top: 0.81rem;
  }
}
.btn{
  height: 3.875rem;
  border-radius: 4.64875rem;
  padding: 0 3.75rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background-image: unset !important;
  width: max-content;
  font-weight: 500;
  letter-spacing: 0.01rem;
  line-height: 140%;
  font-size: $font-size-sm;
  &-lg{
    height: 4.0625rem;
    font-size: $font-size-md;
    letter-spacing: unset;
  }
  &-primary{
    color: $white;
    border: 1px solid $white;
    background-color: transparent;
    transition: .3s;
    font-size: $font-size-sm;
    &:hover, &:active, &:focus{
      background-color: $black !important;
      border: 1px solid $black !important;
      box-shadow: none !important;
    }
  }
  &-outline-black{
    color: $black;
    border: 1px solid $black;
    background-color: transparent;
    transition: .3s;
    font-size: $font-size-sm;
    &:hover, &:active, &:focus{
      background-color: $black !important;
      border: 1px solid $black !important;
      color: #fff;
      box-shadow: none !important;
    }
  }
  &-secondary{
    color: $white;
    border: 1px solid $black;
    background-color: $black;
    transition: .3s;
    font-size: $font-size-sm;
    &:hover, &:active, &:focus{
      background-color: transparent !important;
      border: 1px solid $black !important;
      box-shadow: none !important;
      color: $black !important;
    }
  }
  &-gray{
    color: $white;
    border: 1px solid $white;
    background-color: $white02;
    transition: .3s;
    &:hover{
      background-color: $black02 !important;
      color: $white !important;
    }
  }
}
.properties{
  display: flex;
  align-items: center;
  justify-content: center;
  //flex-wrap: unset !important;
  flex-direction: row !important;
  //@media(max-width:767px){
  //  overflow: auto;
  //  justify-content: flex-start;
  //  width: 100%;
  //}
  &_item{
    padding: 1.19rem 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 17.625rem;
    height: 10rem;
    border-right: 2px solid rgba(29, 29, 29, 0.075);
    transition: .3s;
    transform: scale(1);
    @media(max-width:1199px){
      width: 15rem;
      padding: 1.2rem 1.5rem;
    }
    @media(max-width:767px){
      width: 17rem;
      padding: 1.2rem 2rem;
      &:nth-child(1),&:nth-child(2),&:nth-child(3){
        border-bottom: 2px solid rgba(29, 29, 29, 0.075);
      }
      &:nth-child(3){
        border-right:none
      }
    }
    @media(max-width:575px){
      width: 24rem;
      border-right: none !important;
      border-bottom: 2px solid rgba(29, 29, 29, 0.075);
      &:last-child{
        border-bottom: none;
      }
    }
    &-text{
      text-align: center;
      width: 11rem;
      margin: 0 auto;
      font-size: $font-size-xl;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: 0.015rem;
    }
    &:last-child{
      border: none;
    }
    svg{
      width: 3.25rem;
      height: 3.25rem;
      margin-bottom: 0.75rem;
      stroke: transparent;
      transition: .3s;
    }
    &:hover{
      color: $indigo;
      transform: scale(1.05);
      svg{
        stroke: #006145;
      }
    }
  }
}
.checkbox_list{
  font-size: $font-size-sm;
  padding-left: 1.75rem;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-image: url(../img/check_circle.png);
    background-size: cover;
  }
  &+.checkbox_list{
    margin-top: 0.75rem;
  }
}
.wrapper_grid_table{
  @media(max-width:767px){
    overflow-x: auto;
    width: 100%;
  }
  .grid_table {
    display: grid;
    grid-template-columns: 29.38rem 45rem 1fr;
    @media(max-width:1199px){
      grid-template-columns: 1fr 3fr 2fr;
    }
    @media(max-width:767px){
      min-width: 70rem;
    }
    @media(max-width:575px){
      min-width: 58rem;
      grid-template-columns: 1fr 3fr 3fr;
    }
    svg{
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }
    .header_wrapper{
      display: flex;
      align-items: center;
    }
    .li{
      display: block;
      position: relative;
      padding-left: 1.5rem;
      &::before{
        content: '';
        position: absolute;
        left: 0.375rem;
        top: 0.55rem;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 100%;
        background-color: $dark;
      }
    }
    &-header {
      background-color: $gray-white;
      padding: 0.94rem 2rem;
      font-size: $font-size-lg;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.0125rem;
      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }
      &.last-header {
        border-radius: 0 1rem 1rem 0;
      }
    }
    &-cell {
      border-bottom: 1px solid $gray-white;
      padding: 2rem;
    }
  }
}

.package_grid_table{
  @media(max-width:767px){
    overflow-x: auto;
    width: 100%;
  }
  .grid_table {
    display: grid;
    grid-template-rows: repeat(14, auto);
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr  1fr 1fr;
    .checkbox_list{
      height: 1.25rem;
    }
    @media(max-width:1199px){
      //grid-template-columns: 1fr 3fr 2fr 1fr ;
    }
    @media(max-width:767px){
      min-width: 70rem;
    }
    @media(max-width:575px){
      min-width: 58rem;
      //grid-template-columns: 1fr 3fr 3fr1fr ;
    }
    svg{
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }
    .header_wrapper{
      display: flex;
      align-items: center;
    }
    .li{
      display: block;
      position: relative;
      padding-left: 1.5rem;
      &::before{
        content: '';
        position: absolute;
        left: 0.375rem;
        top: 0.55rem;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 100%;
        background-color: $dark;
      }
    }
    &-header {
      background-color: $gray-white;
      padding: 0.94rem 2rem;
      font-size: $font-size-lg;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.0125rem;
      &:first-child {
        border-radius: 1rem 0 0 1rem;
      }
      &.last-header {
        border-radius: 0 1rem 1rem 0;
      }
    }
    .packages_table_title {
      border-bottom: 1px solid $gray-white;
      padding: 2rem;
      font-size: 1.125rem;
    }
    &-cell{
      font-size: 1rem;
      padding: 2rem;
      border-bottom: 1px solid #FAFAFC;
    }
  }
}

.border-none{
  border: none;
}
.butterfly_layer{
  svg{
    width: 100%;
  }
  .top{
    width: 6.2rem;
    height: 5.92rem;
    top: -3.62rem;
    left: 22.63rem;
    position: absolute;
  }
  .bottom{
    width: 12.43rem;
    height: 11.465rem;
    top: 52.16rem;
    left: 21.9rem;
    position: absolute;
  }
  .right{
    width: 9.74rem;
    height: 9.38rem;
    top: 30.5rem;
    right: 27.8rem;
    position: absolute;
  }
}
.checkbox{
  .checker{
    &::before{
      background-color: $indigo;
    }
  }
}
.main-slider-item-more{
  align-self: center;
  position: absolute;
  right: -5.31rem;
  bottom: 5.62rem;
  display: inline-block;
  font-size: .75rem;
  line-height: 0;
  padding: 1rem;
  border-radius: 50%;
  width: 10.62rem;
  height: 10.62rem;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(50% + 0.125rem);
    height: calc(50% + 0.125rem);
    background-color: $indigo;
    clip-path: circle(100% at 0 0);
    transform-origin: 0 0;
    transform: rotate(270deg);
    z-index: 0;
  }
  &::after {
    display: block;
    padding-bottom: 100%;
    border-radius: 50%;
  }
  .visible {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    border-radius: 50%;
    background-color: $white;
    color: $indigo;
    line-height: 1.25;
    padding: 1rem;
    .text {
      font-size: .75rem;
      text-align: center;
      width: 5rem;
    }
    svg {
      align-self: flex-end;
      height: 1rem;
      fill: $indigo;
      margin-right: 2rem;
    }
  }
}
.form-control.error{
  border-color: #FF0000;
}