// Variables
//@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap&subset=cyrillic');




//BOOTSTRAP

$basefont:'Open Sans', sans-serif;
$fontTitle:'ITC Bauhaus', serif;

$white:       #ffffff !default;
$white02:     rgba(255,255,255,0.2);



$black:       #000000 !default;
$black02:     rgba(0,0,0,0.2);


$dark:        #1D1D1D !default;//primary
$palemint:    #CAD7D0 !default;
$gray-white:  #FAFAFC !default;
$gray:        #666666 !default;
$gray-100:    #DADADA !default;
$gray-200:    #BBBBBB !default;
//$gray-400: #666666 !default;
//$gray-500: #4d4d4d !default;
//$gray-600: #162833 !default;
//
//$gray-700: #495057 !default;
//$gray-800: #343a40 !default;
//$gray-900: #212529 !default;
$border: $gray-200;

//$blue:    #45AAF2 !default;
$indigo:  #006145 !default;
$brown:   #86594B;
//$red:     #FC5C65 !default;
//$dark-red:     #EB3B5A !default;
//$orange:  #FD9644 !default;
//$yellow:  #FED330 !default;
//$dark-yellow:  #CC9941 !default;
//$green:   #26DE81 !default;
//$dark-green:   #20BF6B !default;
$gold:  #CC9941 !default;

$primary:       $dark !default;
//$secondary:     #778CA3 !default;
//$success:       $green !default;
//$info:          $blue !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
$light:         $gray-100 !default;
//$dark:          $gray-600 !default;




$font-color:					$dark;
$font-color-light:				rgba(29, 29, 29, 0.65);
//$font-color-light:				$light;
//$font-color-dark:				$dark;
//$border-color:                  $gray-100;
//$font-family-base:				$basefont;
//$headings-font-family:			$basefont;


$font-size-base:              1.125rem !default; // Assumes the browser default, typically `17px`
$font-size-big:               2rem !default; //32px
$font-size-xl:                1.5rem !default; //24px
$font-size-lg:                1.25rem !default; //20px
$font-size-md:                1.125rem !default; //18px
$font-size-sm:                1rem !default; //16px
$font-size-xs:                0.875rem !default; //14px

$font-100:         100 !default;
$font-300:         300 !default;
$font-400:         400 !default;
$font-500:         500 !default;
$font-600:         600 !default;
$font-700:         700 !default;
$font-900:         900 !default;

//$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.618 !default;

$h1-font-size:                4.625rem !default; //74px
$h2-font-size:                4rem !default; //64px
$h3-font-size:                3.625rem !default; //58px
$h4-font-size:                3rem !default; //48px
$h5-font-size:                2.5rem !default; //40px
$h6-font-size:                2rem !default; //32px


  /*Theme Colors*/
//$accent-color: 				$blue;
//$danger-color: 				$danger;
//$success-color: 			$success;
//$primary-color: 			$primary;
//$info-color: 				$info;
//
//$input-border: 				$gray-300;
//$border: 					$input-border;
//$dark-text: 				$dark;


// Components
//
// Define common padding and border radius sizes and more.

//$border-width:                0.125rem !default;
//$border-color:                $border !default;
//
//
//$input-btn-bg:				  $light;
//$input-btn-padding-y:         .625rem !default;
//$input-btn-padding-x:         1.125rem !default;
//$input-btn-font-size:         $font-size-base !default;
//$input-btn-line-height:       1.375 !default;
//
//$input-btn-focus-color:       $accent-color !default;
//$input-btn-focus-bg:		  white;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
//
//$grid-columns:                12 !default;
//$grid-gutter-width:           30px !default;
//$grid-row-columns:            6 !default;